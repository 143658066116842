import React from "react";
import "./css/index.css";
import ServiceSpotlight from "../../components/ServiceSpotlight/ServiceSpotlight";
import Technology from "../../components/Technology/Technology";
import AffiliateCompany from "../../components/AffiliateCompany/AffiliateCompany";
import Bottom from "../../components/Bottom/Bottom";
import PosType from "../../components/PosType/PosType";
import { ReactComponent as Boxsvg } from "../Main/svg/box.svg";
import { ReactComponent as Clicksvg } from "../Main/svg/click.svg";
import { ReactComponent as Pricesvg } from "../Main/svg/price.svg";
import { ReactComponent as Purchasesvg } from "../Main/svg/purchase.svg";
import { ReactComponent as Chitsvg } from "../Main/svg/chit.svg";
import { ReactComponent as Usesvg } from "./svg/use.svg";
import useIntersectionObserver from "../../hooks/UseIntersectionObserver";
import FooterContainer from "../../components/Footer/container/FooterContainer";
import { UserAgentType } from "../../interface/common/common.inferface";
import PosTypeV2 from "../../components/PosTypeV2/PosTypeV2";
import MainSection5Container from "../../components/MainSection/MainSection5/container/MainSection5Container";
import MainSection12 from "../../components/MainSection/MainSection12/MainSection12";

type Props = {
  userAgent: UserAgentType;
};

const Industry = ({ userAgent }: Props) => {
  const ref = useIntersectionObserver(0.1);
  const ref2 = useIntersectionObserver(0);
  return (
    <main className="industry-page">
      <PosTypeV2
        title="내 가게 업종에 딱맞는 맞춤형 포스"
        sub={`도소매업, 음식점, 생활 의류 등 각 업종에 맞춤형으로
구성된 포스로 간편하게 사용할 수 있습니다`}
      />
      <section className="section2 ">
        <div className="section2-wrapper ">
          {/* 도입업종 컨테이너 */}
          <div ref={ref} className="industry-card-container  hidden-section">
            <div className="title-container">
              <div className="sub-title">도입업종</div>
              <div className="main-title">
                다양한 업종에서 <br />
                편리하게 사용할 수 있습니다
              </div>
            </div>
            {/* 카드  */}
            <div className="card-container">
              {industryArr.map((item, idx) => (
                <div className="card" key={idx}>
                  <div className="title gmarket">
                    {item.content}
                    <img
                      src={`/assets/industry/${userAgent}/${item.src}.png`}
                      srcSet={`/assets/industry/${userAgent}/${item.src}@2x.png 2x, /assets/industry/${userAgent}/${item.src}@3x.png 3x`}
                      alt=""
                    />
                  </div>
                  <div className="sub-title">
                    <p className="bold-text">{item.title}</p>
                    <p className="basic-text">{item.subtitle}</p>
                  </div>
                  <div className="sub-title">
                    <p className="bold-text">{item.title2}</p>
                    <p className="basic-text">{item.subtitle2}</p>
                  </div>
                  <div className="sub-title">
                    <p className="bold-text">{item.title3}</p>
                    <p className="basic-text">{item.subtitle3}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* 사용후기 컨테이너 */}
          <MainSection5Container userAgent={userAgent} />
        </div>
      </section>

      <MainSection12 userAgent={userAgent}  />
      {/* <ServiceSpotlight
        type="work"
        title=""
        thinSubtitle="편리한 사용자 중심의"
        boldSubtitle="UI"
        menuArr={serviceMenuArr}
      /> */}
      <Technology />
      {/* <AffiliateCompany /> */}
      <Bottom />
      <FooterContainer />
    </main>
  );
};
export default Industry;

const industryArr = [
  {
    content: "도소매업",
    src: "box",
    title: "유통매장",
    subtitle: "마트, 편의점, 청과상회, 반찬가게  등",
    title2: "펫 용품, 팬시, 문구점",
    subtitle2: "펫 용품 및 이미용점, 팬시, 문구점 등",
    title3: "주방용품점",
    subtitle3: "식기 및 주방 용품점, 잡화 매장 등",
  },
  {
    content: "생활 의류",
    src: "clothes",
    title: "의료기",
    subtitle: "의료기 상사 및 소매점",
    title2: "꽃집, 뷰티샵",
    subtitle2: "꽃가게, 미용실 네일샵",
    title3: "의류, 잡화",
    subtitle3: "옷, 신발, 액서사리",
  },
  {
    content: "음식점",
    title: "카페 및 베이커리",
    src: "spoon",
    subtitle: "커피, 베이커리, 샌드위치 등",
    title2: "분식",
    subtitle2: "김밥, 떡집, 도넛 매장 등",
    title3: "테이크 아웃 매장",
    subtitle3: "푸드트럭, 이동식 커피점 등",
  },
];